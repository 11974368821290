*:focus {
    outline: 0;
}

.button {
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  padding: 13px 32px;
  font-weight: 400;
  font-size: 17px;
  color: #fff !important;
  text-shadow: rgba(0,0,0,0.2) 0 -1px 0;
  border: 0px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 2px, inset rgba(255, 255, 255, 0.88) 0px 1px 3px -1px;
  background-color: #4589E3;

  @include background-gradient(#5DA4FF, #417BFF);
  @include transition(all 0.2s linear);

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  &:active {
    box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.29);
  }

  &.button-small {
    padding: 10px 33px;
    border: 1px solid #68A2EE;
    font-size: 14px;
    font-weight: 500;
  }
}

.button-outline {
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  color: #fff;
  padding: 12px 31px;
  border: 2px solid #fff;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  background: rgba(0, 0, 0, 0.15);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  height: 53px;

  @include transition(all 0.2s linear);

  &:hover {
    text-decoration: none;
    color: #ddd;
    border-color: #ddd;
  }
}

.button-clear {
  border: 0;
  outline: none;
  padding: 1px;
  display: inline-block;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.075);
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.075);
  -ms-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.075);
  -o-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.075);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.075);

  background: #adb2bb;
  background: -webkit-linear-gradient(#ccd0d6, #adb2bb);
  background: -moz-linear-gradient(#ccd0d6, #adb2bb);
  background: -ms-linear-gradient(#ccd0d6, #adb2bb);
  background: -o-linear-gradient(#ccd0d6, #adb2bb);
  background: linear-gradient(#ccd0d6, #adb2bb);        

  span {
    display: block;
    font-size: 14px;
    padding-left: 23px;
    padding-right: 23px;
    height: 36px;
    line-height: 33px;
    border-radius: 4px;
    font-weight: bold;

    color: #444;
    text-shadow: 0 1px 0 white;
    background: #eff1f4;
    background: -webkit-linear-gradient(#f7f8fa, #eff1f4);
    background: -moz-linear-gradient(#f7f8fa, #eff1f4);
    background: -ms-linear-gradient(#f7f8fa, #eff1f4);
    background: -o-linear-gradient(#f7f8fa, #eff1f4);
    background: linear-gradient(#f7f8fa, #eff1f4);
    -webkit-box-shadow: inset 0 1px 0 white;
    -moz-box-shadow: inset 0 1px 0 white;
    -ms-box-shadow: inset 0 1px 0 white;
    -o-box-shadow: inset 0 1px 0 white;
    box-shadow: inset 0 1px 0 white;
  }

  &:hover {
    text-decoration: none;
  }

  &:active,
  &.active {
    background: #b5bac2;
    background: -webkit-linear-gradient(#b5bac2, #b5bac2);
    background: -moz-linear-gradient(#b5bac2, #b5bac2);
    background: -ms-linear-gradient(#b5bac2, #b5bac2);
    background: -o-linear-gradient(#b5bac2, #b5bac2);
    background: linear-gradient(#b5bac2, #b5bac2);

    span {
      color: #444;
      background: #eff1f4;
      background: -webkit-linear-gradient(#e6e8eb, #eff1f4);
      background: -moz-linear-gradient(#e6e8eb, #eff1f4);
      background: -ms-linear-gradient(#e6e8eb, #eff1f4);
      background: -o-linear-gradient(#e6e8eb, #eff1f4);
      background: linear-gradient(#e6e8eb, #eff1f4);
      -webkit-box-shadow: inset 0 1px 0 rgba(0,0,0,0.1);
      -moz-box-shadow: inset 0 1px 0 rgba(0,0,0,0.1);
      -ms-box-shadow: inset 0 1px 0 rgba(0,0,0,0.1);
      -o-box-shadow: inset 0 1px 0 rgba(0,0,0,0.1);
      box-shadow: inset 0 1px 0 rgba(0,0,0,0.1);
    }
  }
}
