.contact-us-map {
  position: relative;

  #map {
    height: 450px;
    width: 100%;
  }

  .location {
    background: #fff;
    padding: 45px 30px;
    max-width: 300px;
    position: absolute;
    z-index: 10;
    top: 90px;
    border-radius: 3px;
    box-shadow: 0 9px 30px 0 rgba(0,0,0,.09);

    h5 {
      margin-bottom: 20px;
      color: #356c9d;
    }
  }
}

.contact-us-info {
  margin-top: $headerMarginTop;

  .message {
    h3 {
      color: #444;
      font-size: 24px;
      font-weight: 500;
    }

    p {
      font-size: 15px;
      font-weight: 400;
      color: #777;
    }

    form {
      margin-top: 35px;
      width: 80%;

      @media(max-width: 767px) {
        width: 100%;
      }
      
      input[type="text"],
      input[type="email"] {
        width: 80%;

        @media(max-width: 767px) {
          width: 100%;
        }
      }

      label {
        color: #555;
        font-weight: 500;
        margin-bottom: 7px;
      }

      .button {
        margin-top: 20px;
      }

      /* error messages from jquery validate */
      label.error {
        position: relative !important;
        display: inline-block !important;
        margin-top: -2px !important;
        margin-bottom: -8px !important;
        color: #fff !important;
        font-weight: normal !important;
        font-size: 15px !important;
        padding: 5px 7px !important;
        background: #4B9FCF !important;
        border-radius: 4px !important;
        
        &.valid {
          display: none !important;
        }
        
        &:after {
          bottom: 100%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-bottom-color: #4B9FCF;
          border-width: 5px;
          left: 30px;
          margin-left: -5px;
        }
      }
    }
  }

  .contact {
    @media(max-width: 991px) {
      margin-top: 50px;
    }

    .address {
      margin-bottom: 30px;
    }

    h3 {
      color: #444;
      font-size: 18px;
      font-weight: 500;
    }

    p {
      font-size: 15px;
      font-weight: 400;
      color: #777;
    }

    .online-support {
      background: #f8fdfe;
      border: 1px solid #bee1f3;
      margin-top: 40px;
      border-radius: 6px;
      padding: 20px 22px 18px 22px;
      width: 70%;

      @media(max-width: 767px) {
        width: 100%;
      }

      strong {
        font-weight: 500;
        color: #333;
        font-size: 15px;
      }

      p {
        color: #1f95d0;
        margin-top: 14px;
        line-height: 18px;
      }
    }

    .social {
      margin-top: 25px;

      a {
        border: 0px;

        @include transition(all 0.2s linear);
        
        &.tw {
          position: relative;
          top: 2px;
        }

        &:hover {
          opacity: 0.8;
          filter: alpha(opacity=80);
        }
      }       
    }
  }
}
