.geo-compare {
  margin-top: 50px;
  
  .address-col {
    flex-grow: 2;
  }
  
  #map {
    height: 500px;
    margin-bottom: 1rem;
  }
}

.geo-csv {
  margin-top: 50px;

  #map {
    height: 500px;
    margin-bottom: 1rem;
  }
}
