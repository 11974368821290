.header {
  margin-top: $headerMarginTop;
  //text-align: center;

  h3 {
    font-size: 24px;
    color: #444;
    line-height: 25px;
    font-weight: 500;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: #888;
  }
}

.signup-cta {
  margin-top: 120px;

  .wrapper {
    background: #F7F8FB;
    border: 1px solid #EAEDF7;
    border-radius: 5px;
    text-align: center;
    padding: 30px 20px;
  }

  h4 {
    font-size: 20px;
    color: #5F6E7C;
    font-weight: 400;
    margin-top: 15px;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);

    @media(max-width: 767px) {
      font-size: 17px;
      line-height: 23px;
    }
  }
}
